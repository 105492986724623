import { FunctionComponent } from 'react';
import { graphql, navigate } from 'gatsby';
import { Container, Row, Col, Tab, ListGroup } from 'react-bootstrap';

import Context from '@ui/components/context';
import { withTrans } from '@shares/locales/hoc';
import Layout from '@shares/layout';
import SEO from '@shares/seo';

import PromotionContent from '@pages/promotions/sections/content';

import { canUseDOM, PageSection } from '@utils';

import { trackPrmotionDetailBackBtn } from './tracking';
import { PromotionDetailProps } from './interface';
import './style.scss';

const PromotionDetail: FunctionComponent<PromotionDetailProps> = (props) => {
  const { data, pageContext, t } = props;
  const { prefix } = pageContext;
  const content = data.markdownRemark.frontmatter;

  return (
    <Context>
      <Layout section={PageSection.PromotionContent} prefix={prefix} isTransparent={false}>
        <SEO
          title={content[`seo_title_${prefix}`]}
          canonical={content[`rel_canonical_${prefix}`]}
          prefix={prefix}
          path={content.path}
          description={content[`description_${prefix}`]}
          keywords={content[`keywords_${prefix}`]}
        />
        <div className="promotion-detail">
          <Container>
            <Row>
              <Tab.Container id="promotion" defaultActiveKey="1">
                <Col className="col-12" lg={3}>
                  <ListGroup className="promotion-menu">
                    <ListGroup.Item
                      as="a"
                      eventKey="1"
                      className="promotion-menu__item"
                      href={`/${prefix}/promotions`}
                      onClick={(e) => {
                        e.preventDefault();
                        trackPrmotionDetailBackBtn();
                        if (canUseDOM()) {
                          window.location.href = `${window.location.origin}/${prefix}/promotions`;
                          return;
                        }
                        navigate(`/${prefix}/promotions`);
                      }}
                    >
                      <i className="icon-left mr-1" />
                      {t('promotions.back_btn')}
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col className="col-12" lg={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="1">
                      <div className="promotion-content">
                        <PromotionContent prefix={prefix} data={content} t={t} />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Tab.Container>
            </Row>
          </Container>
        </div>
      </Layout>
    </Context>
  );
};

export default withTrans()(PromotionDetail);
export const query = graphql`
  query ($urlPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $urlPath } }) {
      html
      frontmatter {
        path
        seo_title_th
        seo_title_en
        rel_canonical_th
        rel_canonical_en
        description_th
        description_en
        keywords_th
        keywords_en
        title_th
        title_en
        code {
          text
          description
        }
        img_th {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        img_en {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        detail_th
        detail_en
        book_period {
          from
          to
          show
        }
        pickup_period {
          from
          to
          show
        }
        searchbox
        searchbox_title_th
        searchbox_title_en
        interbrand_promotion
        vendor_id
        kind
        term_and_condition_th
        term_and_condition_en
        price_table_th
        price_table_en
        is_long_term_rental
      }
    }
  }
`;
