import DHPicture from '@shares/picture';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './style.scss';
import { FunctionComponent } from 'react';

const stepJSON = [
  {
    no: 1,
    img_src: '/assets/shares/promotion/1.png',
    img_webp_src: '/assets/shares/promotion/1.webp',
    title: `ค้นหาและเลือกรถคลิก "ดูรายละเอียดก่อนจอง"`
  },
  {
    no: 2,
    img_src: '/assets/shares/promotion/2.png',
    img_webp_src: '/assets/shares/promotion/2.webp',
    title: `ใส่รหัสส่วนลดและคลิก "ใช้รหัสส่วนลด"`
  },
  {
    no: 3,
    img_src: '/assets/shares/promotion/3.png',
    img_webp_src: '/assets/shares/promotion/3.webp',
    title: `คลิกเลือกรถคันนี้เพื่อทำการจอง`
  }
];
const HowToUseVoucher: FunctionComponent = () => {
  return (
    <div className="how-to-use-voucher">
      <Row className="justify-content-around">
        {stepJSON.map((step, i) => {
          return (
            <Col className="col-12 mb-3 mb-lg-0" lg={3} key={i}>
              <div className="how-to-use-voucher__step">
                <DHPicture
                  className="dh-picture--promo-step"
                  imgClassName={step.no === 3 ? 'last-step' : ''}
                  webpScr={step.img_webp_src}
                  normalSrc={step.img_src}
                  alt={step.title}
                />
                <div className="step-detail">
                  <div className="no">{step.no}</div>
                  <div className="mt-3">{step.title}</div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default HowToUseVoucher;
