import { sendToDataLayer } from '@utils';
import { PROMOTION_DETAIL_EVENT_CATEGORY } from '@templates/promotions/tracking';

export const trackPromotionDetailSearch = () => {
  sendToDataLayer(undefined, {
    event_category: PROMOTION_DETAIL_EVENT_CATEGORY,
    event_label: 'to_search_section'
  });
};

export const trackPromotionDetailContact = () => {
  sendToDataLayer(undefined, {
    event_category: PROMOTION_DETAIL_EVENT_CATEGORY,
    event_label: 'to_contact_section'
  });
};

export const trackPromotionDetailCode = (promotionName: string, codeDetail: string) => {
  sendToDataLayer(undefined, {
    event_category: PROMOTION_DETAIL_EVENT_CATEGORY,
    event_action: 'copy_code',
    event_label: `${promotionName}_${codeDetail}`
  });
};
