import { sendToDataLayer, getDefaultEventCategory, PageSection, sha256 } from '@utils';
import { ContactInitialValues } from './index';

export enum LongTermFormResp {
  Success = 'success',
  Fail = 'not_success'
}

export const trackContactForm = async (formResp: LongTermFormResp, reqValue: ContactInitialValues) => {
  let encryptEmail = 'undefined';
  let encryptPhoneNumber = 'undefined';
  if (reqValue.email !== '') {
    await sha256(reqValue.email).then((resp) => {
      encryptEmail = resp;
    });
  }
  if (reqValue.phone_number !== '') {
    await sha256(reqValue.phone_number).then((resp) => {
      encryptPhoneNumber = resp;
    });
  }

  const customDimension = {
    rental_purpose: `${reqValue.purposes.length > 0 ? reqValue.purposes.join(',') : 'undefined'}`,
    email: `${encryptEmail}`,
    phone_number: `${encryptPhoneNumber}`,
    call_back_time: `${reqValue.time}`
  };

  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.PromotionContent),
    event_action: `drop_form_${formResp}`,
    event_label: `drivehub_callback`,
    ...customDimension
  });
};
