import { useRef, useState, useEffect, FunctionComponent, useMemo } from 'react';
import moment from 'moment-timezone';
import loadable from '@loadable/component';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Md from 'markdown-it';
import { Row, Col, Button } from 'react-bootstrap';

import { PromotionCode, PromotionDetail, PromotionPeriodFormat } from '@templates/promotions/interface';
import HowToUseVoucher from '@pages/promotions/components/how-to-use-voucher';

import LoadingBox from '@shares/loading';

import { canUseDOM, PageSection } from '@utils';
import { ContactForm } from '@pages/promotions/components/long-term-form';
import './style.scss';

// Tracking
import { trackPromotionDetailSearch, trackPromotionDetailCode, trackPromotionDetailContact } from './tracking';
import { useCallback } from 'react';

const SearchBox = loadable(() => import('@shares/search-box'), {
  fallback: <LoadingBox />
});

interface PromotionContentProps extends TProps {
  prefix: string;
  data: PromotionDetail;
}
const RenderMarkdown = (mdFile: string) => {
  const md = new Md({
    html: true
  });
  return md.render(mdFile);
};

const copyText = (key: string) => {
  if (!canUseDOM()) return;
  const codeBoxRef = document.querySelector(key);
  const textArea = document.createElement('textarea');
  if (!textArea) return;
  if (!codeBoxRef) return;
  const rawCode = codeBoxRef.getAttribute('data-code');
  if (rawCode === null) return;
  textArea.value = rawCode;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('Copy');
  textArea.remove();
};

interface CodeBoxProp {
  code: PromotionCode;
  path: string;
  index: number;
}
interface DateTimeBoxProp {
  dateTime: PromotionPeriodFormat;
  title: string;
  iconType: string;
}
const CodeBox: FunctionComponent<CodeBoxProp> = ({ code, path, index }) => {
  const [copy, setCopy] = useState<boolean>(false);
  useEffect(() => {
    if (copy) setTimeout(() => setCopy(false), 1000);
    return;
  });
  return (
    <Col className={`col-12 code-item`} lg={5} xl={4}>
      <div className="code-detail text-center">
        <p className="font-weight-bold">โค้ดส่วนลด</p>
        <p>
          <small>{code.description}</small>
        </p>
      </div>
      <div
        className={`code-box ${copy ? 'border border-success' : ''}`}
        onClick={() => {
          copyText(`#${path}-code-${index}`);
          trackPromotionDetailCode(path, code.description);
          setCopy(!copy);
        }}
      >
        <span id={`${path}-code-${index}`} data-code={code.text}>
          {code.text}
        </span>
        <i className={`icon-copy-dh ${copy ? 'copied' : ''}`} />
      </div>
    </Col>
  );
};

const DateTimeBox: FunctionComponent<DateTimeBoxProp> = ({ dateTime, title, iconType }) => {
  const today = moment().format('DD-MM-YYYY');
  const fromIsSameDate = moment(dateTime.from, 'DD-MM-YYYY').isSameOrBefore(moment(today, 'DD-MM-YYYY'));
  const fromText = fromIsSameDate ? 'วันนี้' : moment(dateTime.from, 'DD-MM-YYYY').add(543, 'year').format('LL');
  const toText = moment(dateTime.to, 'DD-MM-YYYY').add(543, 'year').format('LL');
  return (
    <Col className={`col-12 mb-3 mb-lg-0`} lg={fromText.length > 6 ? 6 : 5} xl={fromText.length > 6 ? 5 : 4}>
      <div className="datetime-box d-flex">
        <i className={`icon-${iconType} my-auto`} />
        <div className="">
          <p className="font-weight-bold">{title}</p>
          <p style={{ fontSize: 14 }}>{`${fromText} - ${toText}`}</p>
        </div>
      </div>
    </Col>
  );
};

const PromotionContent: FunctionComponent<PromotionContentProps> = (props) => {
  const { data, prefix, t } = props;
  const searchBoxRef = useRef<HTMLDivElement>(null);
  const contactFormRef = useRef<HTMLDivElement>(null);
  const markdownPriceData = data[`price_table_${prefix}`] ? data[`price_table_${prefix}`] : '';
  const markdownTermData = data[`term_and_condition_${prefix}`] ? data[`term_and_condition_${prefix}`] : '';

  const codeArr: PromotionCode[] = data.code ? data.code : [];
  const vendorID = data.vendor_id && data.vendor_id !== '0' ? data.vendor_id : undefined;
  const imageData = useMemo(() => {
    return getImage(data[`img_${prefix}`]);
  }, [data, prefix]);

  const isLongTermRental = data.is_long_term_rental;

  const isPeriodExist = data.book_period || data.pickup_period;
  const isShowPeriod = data.book_period?.show || data.pickup_period?.show;

  const handleTrackingBookNow = useCallback(() => {
    if (isLongTermRental) {
      if (!contactFormRef.current) return;
      trackPromotionDetailContact();
      return contactFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    if (!searchBoxRef.current) return;
    trackPromotionDetailSearch();
    return searchBoxRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [searchBoxRef, contactFormRef, isLongTermRental]);

  return (
    <div className="promotion-content-box">
      <div className="promotion-img">
        {imageData && <GatsbyImage image={imageData} alt={data[`path`]} className="dh-picture--promo" />}
      </div>
      <div className="content mb-3">
        <div className="content__price-table mb-0 mb-lg-3">
          <div className="mb-3">
            <h2>{t('promotions.detail.title')}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: RenderMarkdown(data[`detail_${prefix}`])
              }}
            />
          </div>
          {markdownPriceData !== '' && (
            <div
              className="pb-0 pb-lg-3"
              dangerouslySetInnerHTML={{
                __html: RenderMarkdown(`${markdownPriceData}`)
              }}
            />
          )}
        </div>
        {codeArr.length > 0 && (
          <Row className="content__promotion-code mx-0 mt-4 mt-0">
            {codeArr.map((code, i) => (
              <CodeBox key={i} index={i} path={data.path} code={code} />
            ))}
          </Row>
        )}
        {isPeriodExist && isShowPeriod && (
          <div className="content__promotion-datetime mb-4 mb-lg-5">
            <h2>{t('promotions.detail.pickup_return_title')}</h2>
            <Row>
              {data.book_period && data.book_period.show && (
                <DateTimeBox dateTime={data.book_period} title="ระยะเวลาการจอง" iconType="calendar" />
              )}
              {data.pickup_period && data.pickup_period.show && (
                <DateTimeBox dateTime={data.pickup_period} title="ระยะเวลาการรับรถ" iconType="car" />
              )}
            </Row>
          </div>
        )}
        {markdownTermData !== '' && (
          <div className="content__promotion-term">
            <h2>{t('promotions.detail.term_title')}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: RenderMarkdown(`${markdownTermData}`)
              }}
            />
          </div>
        )}
        {props.data.searchbox && (
          <div className="text-center pt-3 pb-3">
            <Button onClick={handleTrackingBookNow}>
              {isLongTermRental ? t('promotions.detail.book_now_long_term') : t('promotions.detail.book_now_btn')}
            </Button>
          </div>
        )}
      </div>
      {codeArr.length > 0 && (
        <div className="how-to-use mb-3">
          <h2>{t('promotions.detail.how_to_use')}</h2>
          <HowToUseVoucher />
        </div>
      )}
      {isLongTermRental && (
        <div className="long-term-contact-form mb-3" ref={contactFormRef}>
          <ContactForm prefix={prefix} />
        </div>
      )}
      {props.data.searchbox && !isLongTermRental && (
        <div className="search" ref={searchBoxRef}>
          <h2 className="text-center mb-3">{data[`searchbox_title_${prefix}`]}</h2>
          <SearchBox
            bookingBegin={
              data.vendor_id
                ? moment().add(7, 'days').format('YYYY-MM-DD 10:00')
                : moment().add(1, 'days').format('YYYY-MM-DD 10:00')
            }
            bookingEnd={
              data.vendor_id
                ? moment().add(9, 'days').format('YYYY-MM-DD 10:00')
                : moment().add(3, 'days').format('YYYY-MM-DD 10:00')
            }
            prefix={prefix}
            vendorID={vendorID}
            sectionName={PageSection.Promotion}
            promotionTrackingName={data[`seo_title_${prefix}`]}
          />
        </div>
      )}
    </div>
  );
};

export default PromotionContent;
