import { FunctionComponent, useEffect } from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';
import { Row, Col, Form, Card, Button } from 'react-bootstrap';
import { FormikErrors, withFormik, FormikProps } from 'formik';
import { InferType, string as yupString, object as yupObject, array as yupArray } from 'yup';

import { withTrans } from '@shares/locales/hoc';
import { API_LONG_TERM_RENTAL, WEB_LEGACY_LINK } from '@config';

import { trackContactForm, LongTermFormResp } from './tracking';

type ContactProps = PageWithTranslationProps;

const timeOptions = [
  '08:00 - 12:00',
  '12:00 - 13:00',
  '13:00 - 15:00',
  '15:00 - 17:00',
  '17:00 - 18:00',
  '18:00 - 20:00'
];

const purposes = ['purposes.waiting', 'purposes.company', 'purposes.travel', 'purposes.other'];

const contactSchema = () => {
  const phoneRegExp = /([0-9]{9})$/;
  return yupObject<{ [key: string]: any }>().shape({
    full_name: yupString().required('กรุณากรอกชื่อของคุณ'),
    phone_number: yupString()
      .matches(phoneRegExp, 'เบอร์โทรศัพท์ของคุณไม่ถูกต้อง')
      .required('กรุณากรอกเบอร์โทรศัพท์ของคุณ'),
    email: yupString().email('อีเมลของคุณไม่ถูกต้อง').required('กรุณากรอกอีเมลของคุณ'),
    time: yupString(),
    purposes: yupArray().of(yupString().required()).min(1, 'กรุณาเลือกจุดประสงค์ในการเช่ารถ').required(),
    purpose_other: yupString().notRequired()
  });
};
export type ContactInitialValues = InferType<ReturnType<typeof contactSchema>>;

const FORM_NAME_CONTACT = 'long-term-rental-contact-form';

const Contact: FunctionComponent<ContactProps & FormikProps<ContactInitialValues>> = ({
  t,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
  isSubmitting,
  values,
  errors,
  status
}) => {
  const formName = FORM_NAME_CONTACT;

  const getButtonText = (errors: FormikErrors<ContactInitialValues>) => {
    if (status && status.isLoading) {
      return 'กำลังส่งใบเสนอราคา...';
    }
    if (Object.keys(errors).length > 0) {
      return errors[Object.keys(errors)[0]];
    }
    return t('personal.submit');
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      trackContactForm(LongTermFormResp.Fail, values);
    }
  }, [errors, values]);

  const isDisabled = status && status.isLoading === true;
  const isFormError = Object.keys(errors).length > 0;

  return (
    <Card className="p-3">
      <Card.Body>
        <Card.Title as="h3">{t('personal.title')}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{t('personal.subTitle')}</Card.Subtitle>
        <Card.Text>
          <Form onSubmit={handleSubmit}>
            <Form.Control name="form-name" type="hidden" value={formName} />
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="formFullName">
                  <Form.Label>{t('personal.fullName_label')}</Form.Label>
                  <Form.Control
                    name="full_name"
                    placeholder={t('personal.fullName_placeholder')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.full_name}
                    isInvalid={!!errors.full_name}
                    disabled={isSubmitting}
                  />
                  <Form.Control.Feedback type="invalid">{errors.full_name}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formEmail">
                  <Form.Label>{t('personal.email_label')}</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder={t('personal.email_placeholder')}
                    isInvalid={!!errors.email}
                    disabled={isSubmitting}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formPurpose">
                  <Form.Label>{t('detail.purposes_label')}</Form.Label>
                  <Form.Control name="purposes" type="hidden" isInvalid={!!errors.purposes} />
                  {purposes.map((purpose) => (
                    <Form.Check
                      className="mb-1"
                      type="checkbox"
                      id={purpose}
                      key={purpose}
                      value={t(purpose) as string}
                      label={t(purpose)}
                      onChange={(e) => {
                        const value = e.currentTarget.value;
                        if (values.purposes.includes(value)) {
                          const nextValue = values.purposes.filter((current: string) => current !== value);
                          setFieldValue('purposes', nextValue);
                        } else {
                          const nextValue = values.purposes.concat([value]);
                          setFieldValue('purposes', nextValue);
                        }
                      }}
                    />
                  ))}
                  {values.purposes.includes(t('purposes.other')) && (
                    <Form.Control
                      name="purpose_other"
                      value={values.purpose_other}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      className="mt-2"
                      placeholder={t('personal.purposes_other_placeholder')}
                    />
                  )}
                  <Form.Control.Feedback type="invalid">{errors.purposes}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="formPhoneNumber">
                  <Form.Label>{t('personal.phoneNumber_label')}</Form.Label>
                  <Form.Control
                    name="phone_number"
                    type="tel"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone_number}
                    placeholder={t('personal.phoneNumber_placeholder')}
                    isInvalid={!!errors.phone_number}
                    disabled={isSubmitting}
                  />
                  <Form.Control.Feedback type="invalid">{errors.phone_number}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formTime">
                  <Form.Label>{t('personal.time_label')}</Form.Label>
                  <Form.Control
                    as="select"
                    name="time"
                    value={values.time}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={t('personal.time_placeholder')}
                    isInvalid={!!errors.time}
                    disabled={isSubmitting}
                  >
                    {timeOptions.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.time}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="justify-content-md-center mt-3">
              <Col xs={12} md={6}>
                <Button
                  disabled={isFormError || isDisabled}
                  type="submit"
                  className="btn btn-primary btn-block"
                  variant={isFormError && !isDisabled ? 'danger' : 'primary'}
                >
                  {getButtonText(errors)}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export const ContactWithFormik = withFormik<ContactProps, ContactInitialValues>({
  mapPropsToValues: () => ({
    full_name: '',
    email: '',
    phone_number: '',
    time: timeOptions[0],
    purposes: [],
    purpose_other: ''
  }),
  validationSchema: contactSchema(),
  validateOnChange: true,
  validateOnBlur: true,
  handleSubmit: (values, formikBag) => {
    const { t } = formikBag.props;
    const newValues = { ...values };

    if (values.purposes.includes(t('purposes.other'))) {
      newValues.purposes.push(values.purpose_other || '');
      newValues.purposes = newValues.purposes.filter((p) => p !== t('purposes.other'));
    }
    delete newValues.purpose_other;
    formikBag.setStatus({
      isLoading: true
    });

    axios({
      method: 'post',
      url: `${API_LONG_TERM_RENTAL}/long-term-rental/contact`,
      data: {
        ...values
      }
    })
      .then(() => {
        formikBag.setStatus({
          isSuccess: true,
          isLoading: false
        });
        trackContactForm(LongTermFormResp.Success, values);
        navigate(`${WEB_LEGACY_LINK}/th/long-term-rental/successfully`);
      })
      .catch((e) => {
        formikBag.setStatus({
          isSuccess: false,
          isLoading: false
        });
        console.log(e);
      });
    formikBag.setSubmitting(false);
  },
  enableReinitialize: false
})(Contact);

export const ContactForm = withTrans('long-term-rental')(ContactWithFormik);
export default { ContactForm };
